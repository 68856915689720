import { FC } from "react";
import useLogout from "@/hooks/useLogout";

const LogoutBtn: FC = (): JSX.Element => {
  const { logoutHandler } = useLogout();

  return (
    <button onClick={logoutHandler} className="text-danger font-bold">
      ログアウト
    </button>
  );
};

export default LogoutBtn;
