import useProgress from "@/hooks/useProgress";
import { auth } from "@/utils/firebase";
import { signOut } from "firebase/auth";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { clear } from "@/stores/auth";
import { useRouter } from "next/router";
import useCart from "@/hooks/useCart";
import useUserStatus from "@/features/user/hooks/useUserStatus";

type UseLogoutResponse = {
  logoutHandler: VoidFunction;
};

const useLogout = (path = "/"): UseLogoutResponse => {
  const dispatch = useDispatch();
  const router = useRouter();
  const progress = useProgress();
  const { isDesigner } = useUserStatus();
  const { removeAllItemsInCart } = useCart();

  const logoutHandler = useCallback(() => {
    progress(async () => {
      try {
        // 暫定的な対応: プロアカウントじゃなければカートを空にする
        if (!isDesigner) {
          removeAllItemsInCart();
        }

        await signOut(auth);
        dispatch(clear());
        window.alert("ログアウトしました");
        router.push(path);
      } catch (e) {
        alert(e.message);
      }
    });
  }, [path, progress, dispatch, router, isDesigner, removeAllItemsInCart]);

  return {
    logoutHandler,
  };
};
export default useLogout;
